@import '../variables';
@import '../settings';
.has-bootstrap {
  .renewal {

    color: #d52b1e;
    background-color: white;

    .roboto-bold {
      font-family: $font-bold;
    }

    .red-color {
      color: #d52b1e;
    }

    .black-color {
      color: $black;
    }

    .text-bold {
      font-weight: 700;
    }

    .form-cont,
    .ui--modal-notification.active section {
      background: white;
      padding: 4%;
      border-radius: 14px;
      box-shadow: 9px 15px 14px -1px rgba(0, 0, 0, 0.29);
      -webkit-box-shadow: 9px 15px 14px -1px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 9px 15px 14px -1px rgba(0, 0, 0, 0.16);
    }

    .form-cont {
      position: relative;
      @include phone {
        padding-bottom: 4em;
        margin-bottom: 3em !important;
      }
    }

    .ui--modal-notifications.active section {
      background: white;
      padding: 4%;
      border-radius: 14px;
      box-shadow: 9px 15px 14px -1px rgba(0, 0, 0, 0.29);
      -webkit-box-shadow: 9px 15px 14px -1px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 9px 15px 14px -1px rgba(0, 0, 0, 0.16);

    }

    .ui--notification {
      border: none;
      margin: 0 0 1rem;
      padding: 0;

      &::before {
        content: '';
      }

      p {
        &::before {
          content: '';
        }
      }
    }

    .button {
      writing-mode: horizontal-tb !important;
      text-rendering: auto;
      letter-spacing: normal;
      word-spacing: normal;
      text-indent: 0px;
      text-shadow: none;
      text-align: center;
      align-items: flex-start;
      padding: 0.25em 0.25em;
    }

    .image-bill-example img {
      width: auto !important;
      max-width: 550px !important;
      object-fit: contain;
      margin: 0 auto 1rem;
      display: block;
      padding-top: 15%;
    }

    .ContenedorModalFactura {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      box-sizing: border-box;
      text-align: center;
      overflow: auto !important;
      padding-top: 40px;

    }

    .iconInfoCircle {
      cursor: pointer;
      width: 25px;
      height: 25px;
      color: #0097A9;
      position: absolute;
      top: 5px;
      right: 15px;
      overflow: hidden;
    }

    .mb-3 {
      position: relative;
    }

    .textFactura p {
      position: absolute;
      top: 4%;
      right: 3%;
      left: 3%;
      margin: 2rem 0;
      font-size: 1.2rem;
      color: #3c3c3c;
      font-weight: normal;
      line-height: 1.5;
    }

    .form-bottom-text {
      color: #888888;
      font-weight: 300;
    }

    .form-control {
      border: 1px solid #DEDEDE;

      &.error {
        border-color: #d52b1e;
      }
    }

    .btn-close {
      position: absolute;
      right: -12px;
      top: -13px;
      background: white;
      border-radius: 24px;
      width: 32px;
      height: 32px;
    }

    .contPrincipal {
      @include desk {
        margin-top: calc(22% - 200px);
      }

      .titleForm1 {
        color: #3c3c3c;
        font-weight: bold;
      }

      .textForm1 {
        color: #3c3c3c;
        font-size: 1.3rem;
        width: 60%;
        margin: 0 0 2rem;
      }

      .buttonForm1 {
        width: 40%;
        height: 40px !important;
        line-height: 20px !important;
      }

      .icoHoja {
        width: 40px;
        margin: 0 auto 2rem;
      }
    }

    .renewal--detail {
      span.divider {
        margin: .5em 0 .5em .3em;
        width: 100%;
        border-bottom: 2px solid #dddddd;

        &:last-child {
          display: none;
        }
      }

      .row {
        p {
          margin-bottom: 0;
        }

        &.back {
          font-size: .8rem;
          margin-top: 1em;
          @include phone {
            font-size: 1em;
            position: absolute;
            bottom: 2em;
            right: 0;
            left: 0;
          }
        }
      }

      &--price {
        color: $red;
        font-weight: bold;
      }

      &--total {
        color: $red;
        font-size: 1.5em;
      }
    }

    .ModalRenewal3 {
      img {
        width: 50px;
        margin: 0 auto 1rem;
      }
    }

    @media (min-width: 1024px) {
      .ui--modal-notification > div > section {
        width: 38%;
      }
    }
  }
}

.catalog--header {
  h1.renovaciones {
    @include phone {
      font-size: 1.5em;
    }
  }
}

.has-bootstrap.planes-renovaciones,
.has-bootstrap.mobile-renovaciones {
  @import '../base/_slick-theme';

  .slick-dots {
    @include phone {
      margin-bottom: 72px;
      bottom: 0;
    }
  }

  max-width: 1060px;
  margin: auto;
  @include phone {
    margin-top: 5em;
    .my-5 {
      margin: inherit;
    }
  }

  .shadow--tl {
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    overflow: hidden;
  }

  .current {
    &--plan {
      background-color: $red;
      color: white;
    }

    &--benefits {
      gap: 0.5em;
      @include phone {
        gap: 1em;
        flex-direction: column;
        span {
          width: 40%;
        }
        span + span {
          width: 50%;
        }
      }
    }

    &--footer {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    &--redes {
      @include phone {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
    &--price {
      @include phone {
        width: 100%;
        order: 1;
        margin-bottom: 1rem;
        > span {
          width: 33.33333%;
        }
      }
    }

    img {
      height: 20px;
      width: 20px;

      &.red-social {
        min-width: 20px;
        display: inline-flex;
      }
    }

    .plan-desc {
      width: 33%;
      @include phone {
        width: 100%;
        order: 2;
        margin-bottom: 1rem;
      }
    }

    .price {
      color: $red;
      @include tablet {
        margin-top: -8px;
      }

      > span:first-child {
        font-size: 1.5rem;
      }

      sup {
        top: -8px;
      }
    }
  }

  .step {
    border: 1px solid #0197a9;
    background-color: #0197a9;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    min-width: 24px;
    color: white;
    line-height: 1.5;

    &--text {
      color: #666666;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .plan {
    max-width: 280px;

    &--header {
      background-color: #ff8300;
      color: white;
    }

    &--text {
      font-size: .9rem;
    }

    .datos,
    .precio {
      color: $red;
      font-weight: bold;

      sup {
        top: -4px;
      }

      &--suffix {
        font-size: .75rem;
      }
    }

    .renewal-btn {
      background: $red;
      color: white;
      font-family: $font-light;
      font-size: 1rem;
      font-weight: 100;
      line-height: 1.5rem;

      span::after {
        content: ">";
        font-family: "Font Awesome 5 Free", sans-serif;
        display: inline-block;
        padding-left: 7px;
        transform: scale(0.6, 1.3);
        font-weight: 600;
      }

      &:hover {
        color: white;
      }
    }
  }

  .dropdown {
    &--face {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 0;

      i {
        position: relative;
        margin: 0 1rem;

        &.angle-down::before {
          position: absolute;
          top: -8px;
          content: '';
          display: inline-block;
          width: 12px;
          height: 12px;
          border-left: 2px solid $black;
          border-bottom: 2px solid $black;
          transform: rotate(-45deg);
        }

        &.angle-up::before {
          position: absolute;
          content: '';
          display: inline-block;
          width: 12px;
          height: 12px;
          border-right: 2px solid $black;
          border-top: 2px solid $black;
          transform: rotate(-45deg);
        }
      }

      & + input + .dropdown--face {
        margin-top: 1rem;
      }
    }

    &--text {
      font-size: 1rem;
      margin-bottom: 0;
      flex-grow: 1;

      img {
        width: 20px;
        height: 20px;
        margin-left: 20px;
        cursor: pointer;
      }
    }

    &--items {
      margin: 0;
      width: 98%;
      list-style-type: none;
      display: none;
      transition: all 2s;
      padding: 0;
      font-size: 1rem;
      line-height: 2;

      li {
        width: 100%;
        background-position: 0 8px;
        background-size: 1rem 1rem;
        background-repeat: no-repeat;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        img {
          height: 20px;
          width: 20px;
          margin-top: 5px;
        }

        i {
          color: #279dae;
        }

        span {
          margin-left: 5px;
          display: block;
        }

        .detail {
          font-size: .75rem;
        }
      }

      &::before {
        content: "";
        position: relative;
        bottom: 100%;
        right: 20%;
        height: 40px;
        width: 20px;
        transition: all 2s;
      }
    }

    input {
      display: none;

      & + label i {
        &.angle-up {
          display: none;
        }
      }

      &:checked + label i {
        &.angle-up {
          display: block;
        }

        &.angle-down {
          display: none;
        }
      }

      &:checked ~ .dropdown--items {
        display: block;
      }
    }
  }

  .mas-info b {
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
  }
}
.renovaciones {
  .cross--pospago {
    .buttom-purchases {
      height: 2.2rem;
    }
    .slick-dots {
      @include phone {
        margin-bottom: 72px;
        bottom: 0;
      }
    }
    .step {
      border: 1px solid #0197a9;
      background-color: #0197a9;
      border-radius: 50%;
      height: 1.5rem;
      width: 1.5rem;
      min-width: 24px;
      color: white;
      line-height: 1.5;

      &--text {
        color: #666666;
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }
}

.sliderTabsMenu {
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  .stmElement {
    font-size: 1.125rem;
    height: auto;
    font-weight: 700;
    text-align: center;
    position: relative;
    padding: 10px 12px 20px;
    cursor: pointer;
    color: #6c6c6c;
    &::after {
      transition: all .5s ease;
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 4px;
      display: block;
      background-color: #bbb;
    }
    &.active {
      color: #3c3c3c;
      &::after {
        height: 8px;
        background-color: $red;
      }
    }
  }
}

@import './availability';
