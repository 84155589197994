.eSImIndicator {
  background: #ff8300;
  width: max-content;
  padding: 6px 12px 6px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 6px 6px 0;
  position: absolute;
  left: 0;
  top: 86px;

  .label {
    color: #ffffff;
    font-size: 10px;
    padding-left: 6px;
    font-weight: 300;
  }
  .icon {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 480px) {
  .eSImIndicator {
    top: 116px;
  }
}

@media (min-width: 768px) {
  .boxCard {
    --esim-top: 130px;
  }
}
