@import "../variables";
@import "../settings";

.tienda-home2 {
  @import "../base/_slick-theme";
  *, ::after, ::before {
    box-sizing: border-box;
  }

  .container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .p-0 {
    padding: 0!important;
  }

  .text-center {
    text-align: center!important;
  }

  .mt-3 {
    margin-top: 1rem!important;
  }

  .mt-5 {
    margin-top: 3rem!important;
  }

  a {
    color: #0097a9;
    text-decoration: underline;
  }

  .carousel--banner {
    @media screen and (max-width: 1400px) {
      aspect-ratio: 1211/367;
    }
    margin-top: 68px;
    @include phone {
      margin-bottom: 3rem;
      aspect-ratio: 565/339;
    }
    .slick-track {
      align-items: flex-end;
    }
    .slick-dots {
      bottom: calc(-20px - 2rem);
      @include tablet {
        position: unset !important;
        margin: 2rem 0;
      }
      li {
        padding: 0;
        margin: 0 5px !important;
      }
    }
    .slick-prev {
      left: 30px !important;
      z-index: 3;
    }
    .slick-next {
      right: 30px !important;
      z-index: 3;
      right: 0;
    }
    .slick-arrow svg {
      width: 32px !important;
      height: 32px !important;
      top: -7px !important;
      position: relative;
      box-shadow: 3px 3px 10px #dcdcdc;
      border-radius: 50%;
      path {
        &:nth-child(1) {
          fill: #fff;
        }
      }
    }

    .circle-border {
      display: inline-block;
      color: black;
      font: 18px sans-serif;
      width: 16px;
      height: 16px;
      background-color: #d9d9d9 !important;
      border-radius: 10px; /* half of width + borders */
      line-height: 20px; /* vertical center */
      text-align: center; /* horizontal center */
    }

    .slick-active {
      .circle-border {
        background-color: #f3d95a !important;
      }
    }
  }
  .menu {
    display: flex;
    width: 300px;
    position: relative;
    top: 25px;
    padding-left: 0;
    li {
      color: #bbb;
      font-weight: 400;
      &:nth-child(2) {
        color: $red;
        margin-left: 5px;
      }
    }
  }
  /*DESTACADOS*/

  .sliderTabsHome {
    margin-top: 1rem !important;
    .slick-slide {
      margin: 0;
    }
    .sliderTabsMenuHomeResponsive {
      display: none;
    }
    .sliderTabsMenuHome {
      width: 85%;
      margin: 0 auto;
      .stmElementHome {
        font-size: 0.9rem;
        height: auto;
        font-weight: 400;
        text-align: center;
        position: relative;
        padding: 10px 12px 20px;
        cursor: pointer;
        color: #8f8c8c;
        white-space: nowrap;

        &::after {
          transition: all 0.5s ease;
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px;
          display: block;
          background-color: #bbb;
        }

        &.active {
          color: #3c3c3c;
          font-weight: bold;

          &::after {
            height: 8px;
            background-color: $red;
          }
        }
      }
    }
  }
  .seeAll {
    margin: 35px 0 30px;
    h2 {
      font-size: 2.1rem;
      font-weight: 400;
    }
    a {
      font-size: 16px;
      font-weight: 400;
      margin-left: 15px;
      .fa-chevron-right {
        margin-left: 5px;
      }
    }
  }
  /*BOX CARD CAROUSEL*/
  .carouselDestacadosResponsive {
    display: none;
  }
  .carouselDestacados {
    width: 100%;
    margin: 0 auto;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background-color: #fff;
      width: 50px;
      height: 100%;
      top: 0;
    }
    .slick-slide {
      transform: scale(0.7);
      > div {
        height: 100%;
      }
      .boxCard {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg, #fff -8%, transparent);
        }
        &:nth-of-type(3n) {
          &::after {
            background: linear-gradient(90deg, #3c3c3c -8%, transparent);
          }
        }
      }
      .boxCard--button {
        display: none;
      }
      .boxCard--icons div:nth-child(2) a {
        display: none;
      }
      .boxCard--price {
        padding-top: 10px;
        border-bottom: 1px solid #bbb;
        border-top: 1px solid #bbb;
        margin-bottom: 10px;
        p.price-before, p.price-cuota {
          color: $red;
          display: inline-block;
          font-size: 1em;
          margin-right: 0.5rem;
          font-weight: 700;
          @include phone {
            color: #da291c;
            display: inline-block;
            font-size: 1em;
            margin-right: .5rem;
            font-weight: bold;
          }
        }
        p.price-cuota {
          display: block;
          font-size: 1.5em;
          font-weight: bold;
          span {
            font-size: 2rem;
          }
          span:nth-child(even) {
            font-size: 1.5rem !important;
            vertical-align: baseline !important;
          }
          span:first-child {
            display: block;
          }
          span:last-child {
            padding-left: 5px;
          }
          span:first-child, span:last-child {
            font-size: 0.87rem;
          }
        }
        &.cuota {
          .lbl {
            font-weight: normal;
            font-size: 0.87rem;
          }
          p.price-before, p.price {
            color: $grey;
            display: block;
            font-size: 0.8em;
            span {
              color: $grey;
              font-size: 1.2rem;
            }
          }
          p.price {
            font-size: 1.2rem;
            .currency,
            .decimal {
              font-size: .72rem;
              display: inline-block;
              vertical-align: top;
            }
          }
        }
      }
    }
    .slick-current {
      transform: scale(1);
      padding: 20px 0;
      .boxCard {
        &::after {
          display: none;
        }
      }
      .boxCard--button {
        display: block;
        button {
          border-radius: 12px;
        }
      }
      .boxCard--icons div:nth-child(2) a {
        display: block;
      }
      .boxCard--price {
        padding-top: 0;
        border: none;
      }
    }
    .slick-prev {
      z-index: 2;
      height: 100%;
      &::before {
        position: absolute;
        z-index: 3;
        left: 50px;
        top: 30%;
        content: "";
        display: inline-block;
        border-right: 5px solid #b4b6b7;
        border-bottom: 5px solid #b4b6b7;
        width: 50px;
        height: 50px;
        transform: rotate(-225deg);
      }
      svg {
        display: none;
      }
    }
    .slick-next {
      z-index: 2;
      height: 100%;
      right: 0;
      &::before {
        content: "";
        display: inline-block;
        border-right: 5px solid #b4b6b7;
        border-bottom: 5px solid #b4b6b7;
        width: 50px;
        height: 50px;
        transform: rotate(-45deg);
        position: absolute;
        z-index: 3;
        right: 50px;
        top: 30%;
      }
      svg {
        display: none;
      }
    }

    .slick-dots li button:before {
      font-size: 16px;
    }
    .slick-dots li.slick-active button:before {
      color: #f3d95a !important;
    }
  }
  .boxCard {
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    color: #666666;
    flex-direction: column;
    height: 100%;
    border-radius: 20px;
    box-shadow: 3px 3px 10px #dcdcdc;
    &--title {
      margin-bottom: 20px;
      p {
        &:nth-child(1) {
          font-size: 1.5rem;
          font-weight: 700;
          color: #3c3c3c;
          margin: 0;
        }
        &:nth-child(2) {
          font-size: 1.4rem;
          font-weight: 400;
          color: #3c3c3c;
          margin: 0;
        }
      }
      h3 {
        &:nth-child(1) {
          font-size: 1.5rem;
          font-weight: 700;
          color: #3c3c3c;
          margin: 0;
        }
        &:nth-child(2) {
          font-size: 1.4rem;
          font-weight: 400;
          color: #3c3c3c;
          margin: 0;
        }
      }
    }
    &--img {
      img {
        height: 180px;
        object-fit: contain;
      }
    }
    &--description {
      font-weight: 400;
      margin: 20px 0;
    }
    &--price {
      flex: 1;
      div {
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 10px;
        color: #666666;
      }
      p {
        display: flex;
        align-items: flex-start;
        span {
          font-size: 1.9rem;
          color: $red;
          font-weight: bold;
          &:nth-child(3) {
            font-size: 1.3rem;
          }
          &:nth-child(4) {
            font-size: 1.5rem;
            color: $red;
          }
        }
      }
    }

    &--icons {
      div {
        flex-direction: column !important;
        align-items: flex-start !important;
        gap: 15px !important;
        div {
          align-items: flex-start !important;
          &:nth-child(1) {
            font-size: 400;
            margin-bottom: 0 !important;
          }
          &:nth-child(2) {
            flex-direction: row !important;
            gap: 10px;
            flex-wrap: wrap;
          }
        }
        &:nth-child(1) {
          margin-bottom: 20px;
        }
        &:nth-child(2) {
          a {
            text-align: center;
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }
    }
    &--button {
      .link {
        text-decoration: none;
        border-radius: 12px;
        &:hover {
          color: #fff;
        }
      }
    }
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
  }

  @media screen and (max-width: 1024px) {
    .mt-3 {
      margin-top: 1rem !important;
    }
    .m-0 {
      margin: 0 !important;
    }
    .g-4,
    .gy-4 {
      --bs-gutter-y: 1.5rem;
    }

    .g-4,
    .gx-4 {
      --bs-gutter-x: 1.5rem;
    }

    img {
      display: flex;
      width: 100%;
      padding: 0;
    }

    .row {
      --bs-gutter-y: 0;
      display: flex !important;
      flex-wrap: wrap !important;
    }
  }

  img {
    &.red-social {
      height: 20px;
      width: 20px;
      min-width: 20px;
      display: inline-flex;
    }
  }

  .link {
    padding: 0.6em;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    text-align: center;
    background-color: $red;
    color: white;
    border: 1px solid $red;
    border-radius: 5px;
  }

  /* SERVICES */
  .services-title {
    font-size: 3.1rem;
    margin-bottom: 2.5rem;
    margin-top: 0;
    font-weight: bold;
    color: #313131;
  }

  @media screen and (max-width: 767px) {
    .services-title {
      font-size: 2rem;
    }
  }
  @media screen and (max-width: 575px) {
    .services-title {
      font-size: 1.5rem;
    }
  }

  .destacado--detail {
    img {
      height: 20px;
      width: 20px;
      display: inline-flex;
    }
  }

  .promo-items {
    background-color: #313131;
    height: 450px;
    width: 90%;
    margin: 1rem auto 0 !important;
    .box1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        font-size: 2.4rem;
        margin-left: 20%;
        font-weight: bold;
        text-align: left;
        span {
          display: block;
        }
      }
    }
    .box2 {
      padding: 0;
      display: flex;
      align-items: flex-end;
      justify-content: right;
    }
    h2 {
      color: white;
    }
    span {
      color: #ffc721;
    }
    a {
      padding: 0.6em;
      box-sizing: border-box;
      display: inline-block;
      width: 200px;
      margin-top: 20px;
      margin-left: 20%;
      text-decoration: none;
      font-weight: bold;
      text-align: center;
      background-color: $red;
      color: white;
      border: 1px solid $red;
      border-radius: 12px;
    }
    img {
      width: 90%;
      height: 400px;
      border-top-left-radius: 60px;
      object-fit: cover;
    }
  }

  /* BENEIFICIOS */
  .benefit-bar {
    background-color: #f1f1f1;

    .benefit-vertical-line {
      margin-top: 1.5rem;
    }

    @media screen and (max-width: 767px) {
      .container {
        padding: 0 1.25em !important;
      }
    }
    @media (min-width: 576px) {
      .benefit-vertical-line + .benefit-vertical-line {
        border-left: 4px solid #fff;
      }
    }
    @media (min-width: 576px) and (max-width: 991px) {
      .benefit-vertical-line:nth-child(odd) {
        border-left: 0;
      }
    }

    a {
      color: $black;
      text-decoration: none;
    }
  }
  .contenedorLogosServicios {
    padding: 0 !important;
    .benefit-vertical-line {
      margin: 30px 0;
      padding: 12px;
      div {
        margin: 0 !important;
        letter-spacing: -0.9px;
      }
    }
  }
  .benefit-items img {
    width: 80px;
    height: 65px;
  }

  .benefit-items img.compra {
    width: 75% !important;
  }

  .benefit-text {
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: -0.1rem;
    font-family: $font-medium !important;
  }

  /*-----------------------------------*/
  .slick-slide {
    margin: 0;
  }

  /*--------------------------------*/

  /* CAROUSEL DE MARCAS */
  .carousel-cont {
    padding: 0 6%;
  }

  .carousel-brands .slick-slide {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel-brands img {
    width: 100%;
    height: auto;
    padding: 20%;
  }

  .carousel--banner .slick-slide {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel--banner img {
    width: 100%;
    height: auto;
    padding: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  @media screen and (max-width: 991px) {
    .carousel-brands img {
      padding: 25%;
    }
  }
  @media screen and (max-width: 767px) {
    .carousel-brands img {
      padding: 30%;
    }
    .carousel--banner img {
      display: flex;
    }
  }
  @media screen and (max-width: 400px) {
    .carousel-brands .slick-slide {
      height: 160px;
    }
    .carousel--banner .slick-slide {
      height: 260px;
    }
  }

  /* slick Slider adjustments */
  .slick-prev:before {
    content: "";
    display: inline-block;
    /*border-right: 5px solid #da291c;
    border-bottom: 5px solid #da291c;*/
    width: 35px;
    height: 35px;
    transform: rotate(-225deg);
  }

  [dir="rtl"] .slick-prev:before {
    content: "";
    display: inline-block;
    /*border-right: 5px solid #da291c;
    border-bottom: 5px solid #da291c;*/
    width: 35px;
    height: 35px;
    transform: rotate(-45deg);
  }

  .slick-next:before {
    content: "";
    display: inline-block;
    /*border-right: 5px solid #da291c;
    border-bottom: 5px solid #da291c;*/
    width: 35px;
    height: 35px;
    transform: rotate(-45deg);
  }

  [dir="rtl"] .slick-next:before {
    content: "";
    display: inline-block;
    /* border-right: 5px solid #da291c;
     border-bottom: 5px solid #da291c;*/
    width: 35px;
    height: 35px;
    transform: rotate(-225deg);
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }
}
.boxCard--icons {
  .destacado--detail {
    div {
      &:nth-child(3) {
        width: 100%;
        text-decoration: underline;
        color: #0097a9;
        span {
          width: 100%;
          display: block;
          text-align: center;
          text-decoration: underline !important;
          color: #0097a9 !important;
          cursor: pointer;
        }
      }
    }
  }
}
@import "./planes-fijos-modal";

@media (max-width: 1352px) {
  .sliderTabsMenuHome {
    width: 100% !important;
    .slick-list {
      .slick-track {
      }
    }
  }
}

@media (max-width: 760px) {
  .tienda-home2 {
    /*UBICACIÓN DE PAGINA*/
    .menu {
      display: none;
    }
    /*BANNER*/
    .carousel--banner {
      margin-top: 0;
      img {
        height: 334px;
        border-radius: 0 0 40px 40px;
        width: 96%;
        margin: 0 auto !important;
      }
      .slick-dots {
        bottom: calc(-22px - 1rem)
      }
    }
    /*DESTACADOS*/
    .carouselDestacadosResponsive {
      .slick-initialized {
        padding: 20px 0;
        .slick-list {
          padding: 20px 50px !important;
        }
      }
    }

    .carouselDestacados {
      &::after {
        left: 0;
        width: 0px;
      }
      &::before {
        content: "";
        width: 0px;
        position: absolute;
        background-color: #fff;
        top: 0;
        right: 0;
        height: 100%;
        z-index: 2;
      }
      .boxCard {
        margin: 0 auto;
        padding: 15px;
      }
      .slick-next::before {
        display: none;
      }
      .slick-prev::before {
        display: none;
      }
      .slick-list {
        padding-top: 40px;
        .slick-track {
          gap: 15px;
        }
      }
      .slick-center {
        .boxCard {
          //transform: scale(1.1);
        }
      }
      .slick-slide {
        transform: none;
        height: auto !important;
        .boxCard::after {
          display: none;
        }
        .boxCard--price {
          border: none;
        }
        .boxCard--button {
          display: block;
        }
      }
    }
    .services-title {
      margin-top: 1rem !important;
      margin-bottom: 1rem;
      font-size: 2rem !important;
    }
    .sliderTabsHome {
      .sliderTabsMenuHomeResponsive {
        display: block;
        .slick-prev {
          z-index: 2;
          height: 100%;
          &::before {
            content: "\FE64";
            font-size: 25px;
            position: absolute;
            z-index: 3;
            left: -17px;
            top: -3px;
            width: 50px;
            transform: none !important;
            color: #000;
          }
          svg {
            display: none;
          }
        }
        .slick-next {
          z-index: 2;
          height: 100%;
          right: 0;
          &::before {
            content: "\FE65";
            font-size: 25px;
            position: absolute;
            z-index: 3;
            right: -17px;
            top: -3px;
            width: 50px;
            transform: none !important;
            color: #000;
          }
          svg {
            display: none;
          }
        }
        .stmElementHome {
          font-size: 16px;
          position: relative;
          padding-bottom: 15px;
          span {
            width: 80%;
            height: 4px;
            background-color: rgb(193, 193, 193);
            position: absolute;
            bottom: 0;
            left: 10%;
          }
        }
        .active {
          color: #313131;
          span {
            &::after {
              content: "";
              width: 60%;
              height: 6px;
              background-color: $red;
              position: absolute;
              bottom: 0;
              left: 20%;
            }
          }
        }
      }
      .sliderTabsMenuHome {
        display: none;
      }
    }
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
    .seeAll {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      h2 {
        font-size: 1.7rem;
        font-weight: 500;
      }
      a {
        padding-top: 15px;
      }
    }
    /*RENOVACIÓN*/
    .promo-items {
      flex-direction: column;
      height: auto;
      width: 100%;
      .box1 {
        width: 100%;
        margin-top: 60px;
        h2 {
          margin-left: 30px;
          &:nth-child(1) {
            width: 240px;
            margin-bottom: 25px;
          }
          &:nth-child(2) {
            width: 252px;
            font-size: 1.7rem;
            span {
              margin-bottom: 20px;
            }
          }
        }
        a {
          margin-left: 30px;
        }
      }
      .box2 {
        margin-top: 40px;
        width: 100%;
        img {
          width: 95%;
          height: 200px;
        }
      }
    }
    /*BENEFICIO*/
    .contenedorLogosServicios {
      .benefit-vertical-line {
        margin: 10px 0;
      }
    }
    .benefit-text {
      font-size: 1.1rem;
    }
    /*MARCAS*/
    .carousel-cont {
      margin: 0 !important;
      padding: 16px 22.5px !important;
      .slick-dots {
        display: none !important;
      }
    }
    .carousel-brands {
      img {
        padding: 30px;
        width: 157px;
      }
    }
  }
}
